import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { Styled } from './form-input.styled';

export interface IFormInputProps {
  text?: string;
  name: string;
  type: string;
  placeholder?: string;
  step?: number;
  min?: number;
  width?: string;
  isEdit?: boolean;
  maxLength?: number;
  checked?:boolean;
}

export const FormInput: React.FC<IFormInputProps> = ({
  text,
  name,
  type,
  placeholder,
  step,
  width,
  min,
  isEdit,
  maxLength,
  checked
}) => {
  const { values, handleChange, errors, touched } = useFormikContext();
  const isErrorExists = getIn(errors, name) && getIn(touched, name);

  return (
    <Styled.Wrapper>
      {text && <Styled.Label htmlFor={name}>{text}</Styled.Label>}
      <Styled.Input
        id={name}
        name={name}
        type={type}
        min={min}
        step={step}
        onChange={handleChange(name)}
        value={getIn(values, name)}
        placeholder={placeholder}
        width={width}
        isErrorExists={isErrorExists}
        isEdit={isEdit}
        maxLength={maxLength}
        checked={getIn(values, name)}
      />
      {isErrorExists && (
        <Styled.ErrorBox isEdit={isEdit}>{getIn(errors, name)}</Styled.ErrorBox>
      )}
    </Styled.Wrapper>
  );
};
